@charset "utf-8";
/* CSS Document */
.clearfix:after,
before {
	content: '';
	display: block;
	clear: both;
	visibility: hidden;
}
.clearfix {
	zoom: 1;
}
html[xmlns] .clearfix {
	display: block;
}
* html .clearfix {
	height: 1%;
}
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #808285 !important;
	opacity: 1;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #808285 !important;
	opacity: 1;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: #808285 !important;
	opacity: 1;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #808285 !important;
	opacity: 1;
}
li,
a {
	-o-transition: color 0.2s ease-out, all 0.3s ease-in;
	-ms-transition: color 0.2s ease-out, all 0.3s ease-in;
	-moz-transition: color 0.2s ease-out, all 0.3s ease-in;
	-webkit-transition: color 0.2s ease-out, all 0.3s ease-in;
	transition: color 0.2s ease-out, all 0.3s ease-in;
}
/********************* Clearfix ************************/
* {
	margin: 0;
	padding: 0;
}
input {
	outline: 0 !important;
}
ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
a:hover,
a:focus {
	text-decoration: none !important;
	outline: 0 !important;
}
button:focus {
	outline: 0 !important;
}

/* F6FBFF */

body {
	color: #808285 !important;
	font-size: 16px !important;
	font-family: 'Poppins', sans-serif !important;
}
/* body.splash_page{background: url(../images/spalsh-bg.jpg)no-repeat;} */
body.garage {
	overflow: hidden;
}
input,
button,
select,
optgroup,
textarea {
	font-family: 'Poppins', sans-serif !important;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-position-x: 0%;
	background-position-y: 0%;
	background-repeat: repeat;
	background-image: none;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 12px;
	border: 1px solid #000;
	margin-right: 2rem;
	padding: 10px;
	padding-right: 10px;
	padding-right: 2rem;
	width: 100%;
	color: #353434;
	outline: none;
}
.form-control:focus {
	box-shadow: inherit !important;
}

.login-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.login-inner {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #fff;
	max-width: 800px;
	padding: 50px;
}
.login-inner .title {
	text-transform: capitalize;
	color: #ed1c24;
	font-size: 42px;
}
.login-inner .title span {
	color: #808285;
}
.login-inner .logo-thumb {
	width: 60%;
	margin: 40px 0px;
}
.login-inner .email-input {
	margin-top: 20px;
	border-color: #444444;
	border-radius: 0;
	color: #808285;
	padding: 10px 20px;
	height: auto;
	font-size: 22px;
	width: 90%;
}
.login-inner .btn-login {
	margin: 30px 0px;
	background-color: white;
	padding: 10px 40px;
	color: #ed1c24;
	font-size: 22px;
	border: solid 2px #ed1c24;
	transition: all 0.3s;
}
.login-inner .btn-login:hover {
	background-color: #dddddd;
}
.login-inner .label-help {
	color: #ed1c24;
	font-size: 18px;
}
.login-inner .label-help a {
	color: #007bff;
}
.login-inner .label-note {
	margin-top: 10px;
}

.virtual_ex_head {
	position: relative;
	text-align: center;
	display: inline-block;
}
.virtual_ex_head h2 {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	color: #444444;
}
.virtual_ex_head h2 span {
	color: #ed1c24;
}

.tt_logo_2023 {
	width: 60%;
	margin: 30px 0;
}
.login_box .form-group {
	margin-top: 50px;
	margin-bottom: 0;
}
.login_box .form-group small {
	color: #ed1c24;
}
.login_box .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #808285 !important;
	opacity: 1;
}
.login_box .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #808285 !important;
	opacity: 1;
}
.login_box .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #808285 !important;
	opacity: 1;
}
.login_box .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #808285 !important;
	opacity: 1;
}

/* .login-wrapper {
  background-image: url("../images/home_view.png");
} */

@media (max-width: 1950px) {
	.login_box {
		max-width: 600px;
		padding: 15px 40px;
	}
	.login_box h3 {
		margin-bottom: 1rem;
		font-size: 24px;
	}
	.tt_logo_2023 {
		max-width: 300px;
	}
	.login_box .form-group {
		margin-top: 20px;
	}

	.btn.login_btn {
		padding: 7px 30px;
		font-size: 14px;
		border: solid 1px #ed1c24;
	}
	.btn.login_btn:hover {
		border: solid 1px #ed1c24;
	}
	.help_desk_div {
		font-size: 14px;
		bottom: -40px;
	}
	.login-inner .label-help {
		font-size: 14px;
	}
}

@media (max-width: 991px) {
	.login_box {
		max-width: 450px;
		padding: 10px 20px;
	}
	.login_box h3 {
		margin-bottom: 0.5rem;
		font-size: 16px;
	}
	.tt_logo_2023 {
		max-width: 150px;
	}
	.login_box .form-group {
		margin-top: 10px;
	}
	.login_box .form-control.form-control-lg {
		padding: 10px;
		font-size: 12px;
	}
	.btn.login_btn {
		padding: 7px 30px;
		font-size: 12px;
		border: solid 1px #ed1c24;
	}
	.btn.login_btn:hover {
		border: solid 1px #ed1c24;
	}
	.help_desk_div {
		font-size: 12px;
		bottom: -30px;
	}
	.login-wrapper {
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	.login-inner .title {
		font-size: 16px;
	}
	.login-inner .label-help {
		font-size: 11px;
	}
	.login-inner .btn-login {
		margin: 15px 0px;
		padding: 7px 20px;
		font-size: 12px;
	}
	.login-inner {
		padding: 10px 30px;
		max-width: 100%;
		height: 100%;
		width: 100%;
	}
	.login-inner .email-input {
		margin-top: 10px;
		padding: 9px;
		font-size: 14px;
		width: 85%;
	}
	.login-inner .label-note {
		margin-top: 0;
	}
}

@media (max-height: 450px) {
	.login-inner {
		height: 100%;
		padding: 20px;
	}
	.login_box {
		max-width: 600px;
		padding: 10px 20px;
		height: 100%;
		overflow: scroll;
	}
	.is-danger {
		color: red;
		font-size: 10px;
		text-align: left;
		margin-top: 5px;
	}
	.login_box .wel_form_div .form-control.form-control-lg {
		font-size: 12px;
	}
}
